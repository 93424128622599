import React from "react";
import { Link } from "gatsby";

import { PublicLayout } from "../layouts/public";

const Page = (props) => {
    const content = {}
    content.htmlTitle = `The Quality Data Co.`
    content.pageTitle = (<div>Yolk Data Warehouse and Pipe</div>)
    content.pageTagline = (<div>Doing data well is hard. Let Yolk help.</div>)
    content.pageBody = (
        <React.Fragment>

            <div className="section">Yolk is an analytics data warehouse with out-of-the-box ETL/ELT, CDP, and DataOps capabilities. We save you time and money, democratize data engineering tools, and most importantly help you ensure the quality of data that forms decisions, targeting, and ML.</div>

            <div className="section">
                <div className="h2">Our Customers Need To:</div>
                <ul className="indent">
                    <li>Plan</li>
                    <li>Track</li>
                    <li>Collect</li>
                    <li>Warehouse</li>
                    <li>Model</li>
                    <li>Analyze</li>
                    <li>and QA customer data.</li>
                </ul>

            </div>

            <div className="section">
                <div className="h2">Existing solutions:</div>
                <ul className="indent">
                    <li>Only offer one or a few of these functions, meaning teams have to research, decide on, integrate, and maintain many third-party systems.</li>
                    <li>Provide a black-box solution:
                        <ul className="indent">
                            <li>No transparency as to how data is transformed or modeled nor how accurate, reliable, or complete the data is.</li>
                            <li>Data is locked in the box or you're charged exorbitantly to export/sync.</li>
                            <li>Data schema and modeling is defined–and limited–by the solution.</li>
                        </ul>
                    </li>
                    <li>Become expensive at scale.</li>
                    <li>Don't focus on or truly empower data quality.</li>
                </ul>
            </div>

            <div className="section">
                <div className="h2">Yolk as a solution is:</div>
                <ul className="indent">
                    <li>More cost-effective.</li>
                    <li>Easier to setup and maintain.</li>
                    <li>Everything you need plus future-proofed data quality.</li>
                    <li>Transparent, reliable, and powerful.</li>
                </ul>
            </div>

            <div className="cta">
            <a href="https://airtable.com/shrnkzW00Jfxx5Nm4" target="_blank" className="primaryCta">See how it works</a>
            </div>
        </React.Fragment>
    )

    return <PublicLayout {...content} />;
};

export default Page;
